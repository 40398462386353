.Svg {
    display: block;
    max-width:  100% !important;
    max-height: 100% !important;
    > div,
    > span {
        display: contents;
        > svg { display: block; }
    }
}
